const API_URL = process?.env?.['NEXT_PUBLIC_API_URL'] || '';
const NEXT_PUBLIC_API_URL_BACK_OFFICE =
	process?.env?.['NEXT_PUBLIC_API_URL_BACK_OFFICE'] || '';
const ENV: Record<
	string,
	{
		endPoint: string;
		method: string;
		group: string;
		isPermision: boolean;
		details: { es: string; en: string };
	}
> = {
	// Sesion Start
	getSession: {
		endPoint: `${API_URL}/user/getPermissionByUserLogged`,
		method: 'GET',
		details: { es: 'Obtener Session', en: 'Get session' },
		group: '',
		isPermision: false,
	},

	login: {
		endPoint: `${API_URL}/login`,
		method: 'POST',
		details: { es: 'Iniciar Sesión', en: 'Login' },
		group: '',
		isPermision: false,
	},
	register: {
		endPoint: `${API_URL}/auth/register`,
		method: 'POST',
		details: { es: 'Registro de usuario', en: 'Register user' },
		group: '',
		isPermision: false,
	},

	// hasta aqui los uso

	// USERS
	getPermissionByUserLogged: {
		endPoint: `${NEXT_PUBLIC_API_URL_BACK_OFFICE}/user/getPermissionByUserLogged`,
		method: 'GET',
		details: { es: 'User Logged Info', en: 'User Logged Info' },
		group: 'Users',

		isPermision: false,
	},
	logout: {
		endPoint: `${API_URL}/logout/logout`,
		method: 'POST',
		details: { es: 'Cerrar Sesión', en: 'Logout' },
		group: 'Users',
		isPermision: true,
	},

	getUsers: {
		endPoint: `${API_URL}/user`,
		method: 'GET',
		details: { es: 'Obtener usuarios', en: 'Get users' },
		group: 'Users',
		isPermision: true,
	},
	userFilter: {
		endPoint: `${API_URL}/user`,
		method: 'POST',
		details: { es: 'Obtener usuarios filtros', en: 'Get users by filter' },
		group: 'Users',
		isPermision: true,
	},
	getUserById: {
		endPoint: `${API_URL}/user`,
		method: 'GET',
		details: { es: 'Obtener usuario', en: 'Get user' },
		group: 'Users',
		isPermision: true,
	},
	AddUsers: {
		endPoint: `${API_URL}/user`,
		method: 'POST',
		details: { es: 'Adicionar usuarios', en: 'Add user' },
		group: 'Users',
		isPermision: true,
	},
	EditUsers: {
		endPoint: `${API_URL}/user`,
		method: 'PUT',
		details: { es: 'Modificar usuarios', en: 'Update user' },
		group: 'Users',
		isPermision: true,
	},
	DeleteUsers: {
		endPoint: `${API_URL}/user`,
		method: 'DELETE',
		details: { es: 'Eliminar usuarios', en: 'Delete user' },
		group: 'Users',
		isPermision: true,
	},
	changePassword: {
		endPoint: `${API_URL}/user/changePassword`,
		method: 'PUT',
		details: { es: 'Cambio de Contraseña', en: 'Change Password' },
		group: 'Users',
		isPermision: true,
	},
	UpdateImageProfile: {
		endPoint: `${API_URL}/user/UpdateImageProfile`,
		method: 'POST',
		details: { es: 'Cambio de imagen', en: 'Change picture profile' },
		group: 'Users',
		isPermision: true,
	},
	getRoles: {
		endPoint: `${API_URL}/roles`,
		method: 'GET',
		details: { es: 'Obtener roles', en: 'Get roles' },
		group: 'Roles',
		isPermision: true,
	},
	rolesFilter: {
		endPoint: `${API_URL}/roles/filter`,
		method: 'POST',
		details: { es: 'Obtener roles filtros', en: 'Get roles by filter' },
		group: 'Roles',
		isPermision: true,
	},
	getRolesToSelect: {
		endPoint: `${API_URL}/roles/select`,
		method: 'GET',
		details: { es: 'Obtener roles para Select', en: 'Get roles by select' },
		group: 'Roles',
		isPermision: false,
	},
	getRolById: {
		endPoint: `${API_URL}/roles`,
		method: 'GET',
		details: { es: 'Obtener role', en: 'Get role' },
		group: 'Roles',
		isPermision: true,
	},
	createRol: {
		endPoint: `${API_URL}/roles`,
		method: 'POST',
		details: { es: 'Registar roles', en: 'Register roles' },
		group: 'Roles',
		isPermision: true,
	},
	EditRoles: {
		endPoint: `${API_URL}/roles`,
		method: 'PUT',
		details: { es: 'Modificar roles', en: 'Update roles' },
		group: 'Roles',
		isPermision: true,
	},
	DeleteRoles: {
		endPoint: `${API_URL}/roles`,
		method: 'DELETE',
		details: { es: 'Eliminar roles', en: 'Delete roles' },
		group: 'Roles',
		isPermision: true,
	},
	permissionsGroup: {
		endPoint: `${API_URL}/permissions`,
		method: 'GET',
		details: { es: 'Obtener permisos', en: 'Obtener permisos' },
		group: 'Permissions',
		isPermision: true,
	},

	// SOCIALLINK
	createSocialLink: {
		endPoint: `${API_URL}/sociallink`,
		method: 'POST',
		details: { es: 'Registrar redes sociales', en: 'Registrar redes sociales' },
		group: 'SocialLink',
		isPermision: true,
	},
	EditSocialLink: {
		endPoint: `${API_URL}/sociallink`,
		method: 'PUT',
		details: {
			es: 'Modificar Social Link',
			en: 'Modificar Social Link',
		},
		group: 'SocialLink',
		isPermision: true,
	},
	DeleteSocialLinks: {
		endPoint: `${API_URL}/sociallink`,
		method: 'DELETE',
		details: {
			es: 'Eliminar Social Link',
			en: 'Eliminar Social Link',
		},
		group: 'SocialLink',
		isPermision: true,
	},
	getSocialLinkById: {
		endPoint: `${API_URL}/sociallink`,
		method: 'GET',
		details: {
			es: 'Obtener Social Link',
			en: 'Obtener Social Link',
		},
		group: 'SocialLink',
		isPermision: true,
	},
	socialLinkFilter: {
		endPoint: `${API_URL}/sociallink/filter`,
		method: 'POST',
		details: {
			es: 'Obtener Social Link',
			en: 'Obtener Social Link',
		},
		group: 'SocialLink',
		isPermision: true,
	},
	getSocialLinks: {
		endPoint: `${API_URL}/public/sociallink`,
		method: 'GET',
		details: {
			es: 'Obtener Social Link active',
			en: 'Obtener Social Link active',
		},
		group: 'SocialLink',
		isPermision: false,
	},

	uploadImageByElement: {
		endPoint: `${NEXT_PUBLIC_API_URL_BACK_OFFICE}/images/uploadImageByElement`,
		method: 'POST',
		details: {
			es: 'Subir Imagen',
			en: 'Subir Imagen',
		},
		group: 'Images',
		isPermision: true,
	},
	getImageByElement: {
		endPoint: `${NEXT_PUBLIC_API_URL_BACK_OFFICE}/images/getImageByElement`,
		method: 'POST',
		details: {
			es: 'Obtener Imagenes',
			en: 'Obtener Imagenes',
		},
		group: 'Images',
		isPermision: true,
	},
	imageRotatebyDegrees: {
		endPoint: `${NEXT_PUBLIC_API_URL_BACK_OFFICE}/images/imageRotatebyDegrees`,
		method: 'POST',
		details: {
			es: 'Rotar Imagenes',
			en: 'Rotar Imagenes',
		},
		group: 'Images',
		isPermision: true,
	},

	deleteImage: {
		endPoint: `${NEXT_PUBLIC_API_URL_BACK_OFFICE}/images`,
		method: 'DELETE',
		details: {
			es: 'Eliminar Imagen',
			en: 'Eliminar Imagen',
		},
		group: 'Images',
		isPermision: true,
	},
	getImage: {
		endPoint: `${NEXT_PUBLIC_API_URL_BACK_OFFICE}/images/getImage`,
		method: 'GET',
		details: {
			es: 'Listar Imagen',
			en: 'Listar Imagen',
		},
		group: 'Images',
		isPermision: true,
	},
	// Services
	servicesFilter: {
		endPoint: `${API_URL}/services`,
		method: 'POST',
		details: { es: 'Obtener servicios filtros', en: 'Get services by filter' },
		group: 'services',
		isPermision: true,
	},
	// News
	newsFilter: {
		endPoint: `${API_URL}/news`,
		method: 'POST',
		details: { es: 'Obtener noticias filtros', en: 'Get news by filter' },
		group: 'news',
		isPermision: true,
	},

	// About us
	aboutFilter: {
		endPoint: `${API_URL}/about`,
		method: 'POST',
		details: {
			es: 'Obtener sobre nosotros filtros',
			en: 'Get About Us by filter',
		},
		group: 'aboutUs',
		isPermision: true,
	},
	// Cover
	coverFilter: {
		endPoint: `${API_URL}/cover`,
		method: 'POST',
		details: {
			es: 'Obtener portada filtros',
			en: 'Get cover by filter',
		},
		group: 'cover',
		isPermision: true,
	},
	// Link Interest
	socialNetworksFilter: {
		endPoint: `${API_URL}/socialNetworks`,
		method: 'POST',
		details: {
			es: 'Obtener links de interes filtros',
			en: 'Get links interest by filter',
		},
		group: 'socialNetworks',
		isPermision: true,
	},
	// Blog
	blogFilter: {
		endPoint: `${API_URL}/blog`,
		method: 'POST',
		details: {
			es: 'Obtener articulos de blog filtros',
			en: 'Get articles of blog by filter',
		},
		group: 'blog',
		isPermision: true,
	},
};

export default ENV;
